import { Link } from "@remix-run/react";
import { useRef, useState } from "react";
import Tilt from "react-parallax-tilt";

import screenshot1 from "~/images/screenshot1.png";

export function headers() {
  return {
    // max-age = 5 minutes
    // stale-while-revalidate = 1 week
    "Cache-Control": "max-age=300, stale-while-revalidate=604800",
  };
}

export default function Index() {
  return (
    <>
      <section className="relative text-white overflow-hidden border-zinc-900 border-b flex-grow">
        <div className="stars" />
        <div className="stars2" />
        <div className="stars3" />

        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="pt-44">
            <div className="pb-10 text-center">
              <h1 className="leading-tighter mb-7 text-3xl font-bold tracking-tight md:text-7xl text-white relative">
                Hi 👋
                <br />
                We're making{" "}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-400 to-brand-700">
                  diffs.io
                </span>
              </h1>
              <p className="mb-10 text-xl md:text-3xl tracking-tight text-zinc-400">
                An open-source code review experience that just works.
                <br />
                Ship <span className="text-white">better code, faster</span>.
              </p>
              <div className="mx-auto max-w-xs flex flex-col items-center sm:max-w-none justify-center">
                <Link
                  to="/auth/github"
                  className="font-medium text-base max-w-xs px-6 py-2 justify-center shadow-sm shadow-black/10 border border-white/20 hover:border-white/30 focus:border-white/30 u-text-gray-900 bg-white/5 hover:bg-white/5 backdrop-blur-sm transition-colors duration-50 inline-flex items-center rounded-full"
                >
                  <svg
                    className="mr-2 -ml-1 w-4 h-4"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="github"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path
                      fill="currentColor"
                      d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                    />
                  </svg>

                  <span>Join the waitlist with GitHub</span>
                  <svg
                    className="ml-2 -mr-1 h-3 w-3 flex-shrink-0 fill-current text-zinc-400"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                      fillRule="nonzero"
                    />
                  </svg>
                </Link>
                <span className="my-3 text-zinc-300">or</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="font-semibold text-brand-400 hover:underline"
                >
                  Chat and contribute on Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="border-zinc-900 border-b py-20">
        <div className="mx-auto max-w-screen-xl xl:grid xl:grid-cols-3 gap-16">
          <div className="xl:col-span-2">
            <MarketingCard>
              <h2 className="text-2xl font-semibold text-zinc-400 mb-5">
                A better way to work
              </h2>
              <p className="text-zinc-300">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                vitae semper nisl. Sed vitae semper nisl. Sed vitae semper nisl.
              </p>
            </MarketingCard>
          </div>
        </div>
      </section>
    </>
  );
}

function MarketingCard({ children }: { children: React.ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  function onPointerMove(event: React.PointerEvent<HTMLDivElement>) {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    setPosition({ x, y });
  }

  return (
    <Tilt
      className="rounded-xl p-[1px] transition-all preserve-3d"
      perspective={5000}
      glareEnable={true}
      glareBorderRadius="0.75rem"
      glareMaxOpacity={0.1}
      scale={1.02}
      style={{
        background: position
          ? `radial-gradient(circle at ${position.x}% ${position.y}%, #272728 0%, #272728 20%, black 150%)`
          : "radial-gradient(circle at 50% 50%, #272728, #272728)",
      }}
    >
      {/* {position ? (
          <div
            className="absolute w-[4px] h-[4px] -ml-[2px] -mt-[2px] bg-red-500 z-10"
            style={{
              top: `${position.y}%`,
              left: `${position.x}%`,
            }}
          />
        ) : null} */}
      <div
        className="rounded-xl bg-white dark:bg-black w-full opacity-100 preserve-3d"
        ref={ref}
        onPointerMove={onPointerMove}
        onPointerOut={() => setPosition(null)}
      >
        <div className="w-full preserve-3d">
          <div
            className="rounded-xl rounded-b-none w-full h-full opacity-40 absolute top-0 left-0 right-0"
            style={{
              backgroundImage: `url(${screenshot1})`,
              backgroundPosition: "top",
              backgroundSize: "100% 100%",
              filter: "blur(8px)",
            }}
          />
          <div className="rounded-xl rounded-b-none w-full h-full absolute top-0 left-0 right-0 bg-gradient-to-t from-black via-transparent to-transparent" />
          <div className="p-14 rounded-xl preserve-3d">
            <img
              width="100%"
              src={screenshot1}
              style={{
                transform: "translateZ(40px)",
              }}
              alt=""
              className="rounded-sm w-full h-full"
            />
          </div>
        </div>
        <div className="bg-black rounded-xl rounded-t-none preserve-3d">
          <div
            className="p-14 pt-0"
            style={{
              transform: "translateZ(40px)",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Tilt>
  );
}
